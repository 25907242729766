import React from 'react';

const Project = () => {
  return (
    <>
      <main>
        <div className="breadcrumb__pt">
          <div
            className="breadcrumb__area breadcrumb__overlay breadcrumb__height p-relative fix"
            style={{ backgroundImage: 'url(assets/img/breadcurmb/breadcurmb.jpg)' }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xxl-12">
                  <div className="breadcrumb__content z-index">
                    <div className="breadcrumb__section-title-box mb-20">
                      <h3 className="breadcrumb__title tp-split-text tp-split-in-right">Project</h3>
                    </div>
                    <div className="breadcrumb__list">
                      <span><a href="index.html">Home</a></span>
                      <span className="dvdr"><i className="fa-solid fa-angle-right"></i></span>
                      <span>Project</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tp-project-4-area pt-150 pb-90 fix">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-project-title-box text-center mb-30">
                  <span className="tp-section-subtitle">Latests Project</span>
                  <h3 className="tp-section-title">Where Form Meets Function <br /> with Flair designer</h3>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="tp-project-filter masonary-menu text-center pb-60">
                  <button data-filter="*" className="active"><span>All</span></button>
                  <button data-filter=".cat1"><span>Exterior</span></button>
                  <button data-filter=".cat2"><span>Interior</span></button>
                
                </div>
              </div>
            </div>
            <div className="row grid gx-35">
              <div className="col-lg-4 col-md-6 grid-item cat5 cat3 cat2">
                <div className="tp-project-4-item p-relative">
                  <div className="tp-project-4-thumb">
                    <img src="assets/img/project/project-4-1.jpg" alt="" />
                  </div>
                  <div className="tp-project-4-content">
                    <h6 className="tp-project-4-title"><a href="project-details.html">New York Gallery</a></h6>
                    <p>orem npsum dolor sit conse cteturs adipiscing elit</p>
                    <a className="tp-btn-border-lg white-border" href="project-details.html">
                      <span>Read More</span>
                    </a>
                  </div>
                </div>
              </div>
              {/* Rest of the project items... */}
            </div>
          </div>
        </div>

        <div className="tp-contact-area p-relative black-bg fix pt-115 pb-120 z-index">
          <div className="tp-contact-shape-1">
            <img src="assets/img/contact/shape-1-1.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="tp-contact-left">
                  <div className="tp-contact-title-box mb-20">
                    <span className="tp-section-subtitle tp-split-text tp-split-in-right">Message</span>
                    <h3 className="tp-section-title text-white tp-split-text tp-split-in-right">Designs For Every Budget  <br />
                      Please Contact Us ?</h3>
                  </div>
                  <div className="tp-contact-text">
                  <p>By submitting this form you agree to the privacy policy & terms and condition. </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".7s">
                <div className="tp-contact-right">
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 mb-20">
                        <div className="tp-contact-input-box">
                          <input type="email" placeholder="Your Email" />
                          <div className="tp-contact-icon">
                            <span>
                              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9727 1.76172L13.2227 13.1094C13.1953 13.3828 13.0312 13.6289 12.7852 13.7656C12.6484 13.8203 12.5117 13.875 12.3477 13.875C12.2383 13.875 12.1289 13.8477 12.0195 13.793L8.68359 12.3984L7.28906 14.4766C7.17969 14.668 6.98828 14.75 6.79688 14.75C6.49609 14.75 6.25 14.5039 6.25 14.2031V11.5781C6.25 11.3594 6.30469 11.168 6.41406 11.0312L12.375 3.375L4.33594 10.6211L1.51953 9.44531C1.21875 9.30859 1 9.03516 1 8.67969C0.972656 8.29688 1.13672 8.02344 1.4375 7.85938L13.6875 0.886719C13.9609 0.722656 14.3438 0.722656 14.6172 0.914062C14.8906 1.10547 15.0273 1.43359 14.9727 1.76172Z" fill="currentcolor" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Rest of the form fields... */}
                    </div>
                  </form>
                  <button className="tp-btn-border height w-100"><span>Get Free Quote</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Project;