import React from 'react';
import logo from '../assets/img/manish logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>

            <div class="tp-footer-area tp-footer-style-2 tp-footer-style-3 black-bg pt-75 pb-50">
               <div class="container">
                  <div class="row">
                     <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                        <div class="tp-footer-widget footer-cols-3-1">
                           <div class="tp-footer-logo">
                              <Link to="/home">
                                 <img src={logo} alt="" style={{height: '150px'}}/>
                              </Link>
                           </div>
                           {/* <div class="tp-footer-text pb-5">
                              <p>Corporate business typically refers to large-scale enterp mansola it becuse organizat
                                 enterprises or organizat</p>
                           </div> */}
                           <div class="tp-footer-social">
                              <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                              <a href="#"><i class="fa-brands fa-twitter"></i></a>
                              <a href="#"><i class="fa-brands fa-instagram"></i></a>
                              <a href="#"><i class="fa-brands fa-pinterest-p"></i></a>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-2 col-md-6 col-sm-6 mb-50 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".5s">
                        <div class="tp-footer-widget footer-cols-3-2">
                           <h4 class="tp-footer-title">Our Services</h4>
                           <div class="tp-footer-list">
                              <ul>
                                 <li><Link to="/services">ARCHITECTURAL SERVICES</Link></li>
                                 <li><Link to="/services">INTERIOR DESIGNING</Link></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-50 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".7s">
                        <div class="tp-footer-widget footer-cols-3-3">
                           <h4 class="tp-footer-title">Projects</h4>
                           <div class="tp-footer-list">
                              <ul>
                                 <li><a href="#">-</a></li>
                                 <li><a href="#">-</a></li>
                                 <li><a href="#">-</a></li>
                                 <li><a href="#">-</a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-50 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
                        <div class="tp-footer-widget d-flex justify-content-lg-end footer-cols-3-4">
                           <div class="tp-footer-contact-box">
                              <h4 class="tp-footer-title">Office Information</h4>
                              <div class="tp-footer-contact">
                                 <ul>
                                    <li>
                                    <a target="_blank"
                           href="https://www.google.com/maps/place/DLF+Phase+3,+Sector+24,+Gurugram,+Haryana/@28.4941066,77.0930558,15z/data=!3m1!4b1!4m6!3m5!1s0x390d1ecb48154ca3:0x9827105904cf8c22!8m2!3d28.4916812!4d77.094897!16s%2Fg%2F1tv22jw3?entry=ttu">3/6, U Block, DLF Phase-3, Sector-24, Gurugram, - 122002, Haryana</a>
                                    </li>
                                    <li>
                                       <a href="#">8708507953, 7404288715</a>
                                    </li>
                                    <li>
                                    <a
                           href="mailto:manish.bansal634@gmail.com">
                           manish.bansal634@gmail.com</a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
            <div class="tp-copyright-area tp-copyright-style-2 black-bg tp-copyright-border tp-copyright-height">
               <div class="container">
                  <div class="row align-items-center">
                     <div class="col-lg-6  wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                        <div class="tp-copyright-left tp-copyright-left-pt text-center text-lg-start">
                           <p>© Manish Bansal Design | All Rights Reserved</p>
                        </div>
                     </div>
                     <div class="col-lg-6  wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".5s">
                        <div class="tp-copyright-right text-center text-lg-end">
                           Developed By - <a href="https://www.hashedbit.com/" target='_blank'>HashedBit Innovations</a>
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </footer>
  )
}

export default Footer
