import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/manish logo.png'

const Topbar = () => {

    const [mobilemenu, setmobilemenu] = useState(false);

    const opencloseMobileMenu = () => {
        console.log('openMobileMenu');
        setmobilemenu(mobilemenu => !mobilemenu);
    }
    return (
        <>
            {/* <div id="loading">
      <div id="loading-center">
         <div id="loading-center-absolute">
            <div class="object" id="object_four"></div>
            <div class="object" id="object_three"></div>
            <div class="object" id="object_two"></div>
            <div class="object" id="object_one"></div>
         </div>
      </div>
   </div> */}


            <div class="back-to-top-wrapper">
                <button id="back_to_top" type="button" class="back-to-top-btn">
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6L6 1L1 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            </div>


            <div class="search__popup d-none">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="search__wrapper">
                                <div class="search__top d-flex justify-content-between align-items-center">
                                    <div class="search__logo">
                                        <a href="index.html">
                                            <img src="assets/img/logo/logo-white.png" alt="" />
                                        </a>
                                    </div>
                                    <div class="search__close">
                                        <button type="button" class="search__close-btn search-close-btn">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 1L1 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M1 1L17 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="search__form">
                                    <form action="#">
                                        <div class="search__input">
                                            <input class="search-input-field" type="text" placeholder="Type here to search..." />
                                            <span class="search-focus-border"></span>
                                            <button type="submit">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.55 18.1C14.272 18.1 18.1 14.272 18.1 9.55C18.1 4.82797 14.272 1 9.55 1C4.82797 1 1 4.82797 1 9.55C1 14.272 4.82797 18.1 9.55 18.1Z"
                                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M19.0002 19.0002L17.2002 17.2002" stroke="currentColor" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="tpoffcanvas-area">
                <div className={mobilemenu ? 'tpoffcanvas opened' : 'tpoffcanvas'}>
                    <div class="tpoffcanvas__close-btn">
                        <button class="close-btn" onClick={opencloseMobileMenu}><i class="fal fa-times"></i></button>
                    </div>
                    <br></br>
                    {/* <div class="tpoffcanvas__logo">
                        <a href="index.html">
                            <img src="assets/img/logo/logo-white.png" alt="" />
                        </a>
                    </div>
                    <div class="tpoffcanvas__title">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima incidunt eaque ab cumque, porro maxime
                            autem sed.</p>
                    </div> */}
                    <div class="tp-main-menu-mobile d-xl-none">

                        <nav class="tp-main-menu-content">
                            <ul>
                                <li>
                                    <Link to="/home">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/projects">Projects</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                                {/* <li class="has-dropdown">
                              <a href="index.html"><span>Home</span><button class="dropdown-toggle-btn"><i class="fal fa-angle-right"></i></button></a>
                              <div class="tp-submenu submenu has-homemenu">
                                 <div class="row gx-6 row-cols-1 row-cols-md-2 row-cols-xl-5">
                                    <div class="col homemenu">
                                       <div class="homemenu-thumb mb-15">
                                          <img src="assets/img/menu/home-1.jpg" alt="">
                                          <div class="homemenu-btn">
                                             <a class="tp-menu-btn" href="index.html"><span>Multi page</span></a>
                                             <a class="tp-menu-btn" href="index-one-page.html"><span>One Page</span></a>
                                          </div>
                                       </div>
                                       <div class="homemenu-content text-center">
                                          <h4 class="homemenu-title">
                                             <a href="index.html"><span>Home 01</span></a>
                                          </h4>
                                       </div>
                                    </div>
                                    <div class="col homemenu">
                                       <div class="homemenu-thumb mb-15">
                                          <img src="assets/img/menu/home-2.jpg" alt="">
                                          <div class="homemenu-btn">
                                             <a class="tp-menu-btn" href="index-2.html"><span>Multi page</span></a>
                                             <a class="tp-menu-btn" href="index-2-one-page.html"><span>One Page</span></a>
                                          </div>
                                       </div>
                                       <div class="homemenu-content text-center">
                                          <h4 class="homemenu-title">
                                             <a href="index-2.html"><span>Home 02</span></a>
                                          </h4>
                                       </div>
                                    </div>
                                    <div class="col homemenu">
                                       <div class="homemenu-thumb mb-15">
                                          <img src="assets/img/menu/home-3.jpg" alt="">
                                          <div class="homemenu-btn">
                                             <a class="tp-menu-btn" href="index-3.html"><span>Multi page</span></a>
                                             <a class="tp-menu-btn" href="index-3-one-page.html"><span>One Page</span></a>
                                          </div>
                                       </div>
                                       <div class="homemenu-content text-center">
                                          <h4 class="homemenu-title">
                                             <a href="index-3.html"><span>Home 03</span></a>
                                          </h4>
                                       </div>
                                    </div>
                                    <div class="col homemenu">
                                       <div class="homemenu-thumb mb-15">
                                          <img src="assets/img/menu/home-4.jpg" alt="">
                                          <div class="homemenu-btn">
                                             <a class="tp-menu-btn" href="index-4.html"><span>Multi page</span></a>
                                             <a class="tp-menu-btn" href="index-4-one-page.html"><span>One Page</span></a>
                                          </div>
                                       </div>
                                       <div class="homemenu-content text-center">
                                          <h4 class="homemenu-title">
                                             <a href="index-4.html"><span>Home 04</span></a>
                                          </h4>
                                       </div>
                                    </div>
                                    <div class="col homemenu">
                                       <div class="homemenu-thumb mb-15">
                                          <img src="assets/img/menu/home-5.jpg" alt="">
                                          <div class="homemenu-btn">
                                             <a class="tp-menu-btn" href="index-5.html"><span>Multi page</span></a>
                                             <a class="tp-menu-btn" href="index-5-one-page.html"><span>One Page</span></a>
                                          </div>
                                       </div>
                                       <div class="homemenu-content text-center">
                                          <h4 class="homemenu-title">
                                             <a href="index-5.html"><span>Home 05</span></a>
                                          </h4>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </li>
                           <li>
                              <a href="about-us.html"><span>About Us </span></a>
                           </li>
                           <li class="has-dropdown">
                              <a href="service.html"><span>Services</span><button class="dropdown-toggle-btn"><i class="fal fa-angle-right"></i></button></a>
                              <ul class="submenu tp-submenu">
                                 <li><a href="service.html"><span>Service</span></a></li>
                                 <li><a href="service-details.html"><span>Service Details</span></a></li>
                              </ul>
                           </li>
                           <li class="has-dropdown">
                              <a href="project.html"><span>Projects</span><button class="dropdown-toggle-btn"><i class="fal fa-angle-right"></i></button></a>
                              <ul class="submenu tp-submenu">
                                 <li><a href="project.html"><span>Project</span></a></li>
                                 <li><a href="project-details.html"><span>Project Details</span></a></li>
                              </ul>
                           </li>
                           <li class="has-dropdown">
                        <a href="blog-classic.html"><span>Blog</span><button class="dropdown-toggle-btn"><i class="fal fa-angle-right"></i></button></a>
                              <ul class="submenu tp-submenu">
                                 <li><a href="blog-classic.html"><span>Blog Classic</span></a></li>
                                 <li><a href="blog-details.html"><span>Blog Details</span></a></li>
                              </ul>
                           </li>
                           <li class="has-dropdown">
                              <a href="#"><span>Page</span><button class="dropdown-toggle-btn"><i class="fal fa-angle-right"></i></button></a>
                              <ul class="submenu tp-submenu">
                                 <li><a href="team.html"><span>Team</span></a></li>
                                 <li><a href="team-details.html"><span>Team Details</span></a></li>
                                 <li><a href="appointment.html"><span>appointment</span></a></li>
                                 <li><a href="faq.html"><span>Faq</span></a></li>
                                 <li><a href="price.html"><span>price</span></a></li>
                                 <li><a href="shop.html"><span>shop</span></a></li>
                                 <li><a href="shop-details.html"><span>shop details</span></a></li>
                                 <li><a href="cart.html"><span>cart</span></a></li>
                                 <li><a href="checkout.html"><span>checkout</span></a></li>
                                 <li><a href="error.html"><span>error</span></a></li>
                              </ul>
                           </li>
                           <li><a href="contact.html"><span>Contact</span></a></li> */}
                            </ul>
                        </nav>

                    </div>
                    <div class="tpoffcanvas__contact-info">
                        <div class="tpoffcanvas__contact-title">
                            <h5>Contact us</h5>
                        </div>
                        <ul>
                            <li>
                                <i class="fa-light fa-location-dot"></i>
                                <a href="https://www.google.com/maps/place/United+States/@21.2541411,-83.1622716,7.17z/data=!4m6!3m5!1s0x54eab584e432360b:0x1c3bb99243deb742!8m2!3d37.09024!4d-95.712891!16zL20vMDljN3cw?entry=ttu"> 1901 3/6
                                    ,U Block,DLF Phase-3,Sector-24,Gurugram,- 122002,Haryana .</a>

                            </li>
                            <li>
                                <i class="fas fa-envelope"></i>
                                <a href="#">manishbansaldesigns@gmail.com</a>
                            </li>
                            <li>
                                <i class="fal fa-phone-alt"></i>
                                <a href="#">8708507953, 7404288715</a>
                            </li>

                        </ul>
                    </div>

                    <div class="tpoffcanvas__social">
                        <div class="social-icon">
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={mobilemenu ? 'body-overlay apply' : 'body-overlay'}></div>


            <div class="tp-header-top-area tp-header-top-height theme-bg z-index-5">
                <div class="container custom-container-1">
                    <div class="row align-items-center">
                        <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6">
                            <div class="tp-header-top-left">
                                <ul class="text-center text-sm-start">
                                    <li class="d-none d-lg-inline-block"><i class="fa-solid fa-envelope"></i><a
                                        href="mailto:manish.bansal634@gmail.com">
                                        manish.bansal634@gmail.com</a></li>
                                    <li><i class="fa-solid fa-location-dot"></i> <a target="_blank"
                                        href="https://www.google.com/maps/place/DLF+Phase+3,+Sector+24,+Gurugram,+Haryana/@28.4941066,77.0930558,15z/data=!3m1!4b1!4m6!3m5!1s0x390d1ecb48154ca3:0x9827105904cf8c22!8m2!3d28.4916812!4d77.094897!16s%2Fg%2F1tv22jw3?entry=ttu">3/6, U Block, DLF Phase-3, Sector-24, Gurugram, - 122002, Haryana</a></li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 d-none d-sm-block">
                            <div class="tp-header-top-social text-end">
                                <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







            <>

                <div class="tp-header-area  tp-header-style-3 grey-bg z-index-5">
                    <div class="container custom-container-1">
                        <div class="row align-items-center">
                            <div class="col-xl-2 col-lg-4 col-6">
                                <div class="tp-header-logo">
                                    <Link to='/home'><img src={logo} alt="" /></Link>
                                </div>
                            </div>
                            <div class="col-xl-7 d-none d-xl-block">
                                <div class="tp-header-menu">
                                    <nav class="tp-main-menu-content">
                                        <ul>
                                            {/* <li class="has-dropdown-2">
                                            <a href="index.html">Home</a>
                                            <div class="tp-submenu submenu has-homemenu">
                                                <div class="row gx-6 row-cols-1 row-cols-md-2 row-cols-xl-5">
                                                    <div class="col homemenu">
                                                        <div class="homemenu-thumb mb-15">
                                                            <img src="assets/img/menu/home-1.jpg" alt="" />
                                                            <div class="homemenu-btn">
                                                                <a class="tp-menu-btn" href="index.html">Multi page</a>
                                                                <a class="tp-menu-btn" href="index-one-page.html">One Page</a>
                                                            </div>
                                                        </div>
                                                        <div class="homemenu-content text-center">
                                                            <h4 class="homemenu-title">
                                                                <a href="index.html">Home 01</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col homemenu">
                                                        <div class="homemenu-thumb mb-15">
                                                            <img src="assets/img/menu/home-2.jpg" alt="" />
                                                            <div class="homemenu-btn">
                                                                <a class="tp-menu-btn" href="index-2.html">Multi page</a>
                                                                <a class="tp-menu-btn" href="index-2-one-page.html">One Page</a>
                                                            </div>
                                                        </div>
                                                        <div class="homemenu-content text-center">
                                                            <h4 class="homemenu-title">
                                                                <a href="index-2.html">Home 02</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col homemenu">
                                                        <div class="homemenu-thumb mb-15">
                                                            <img src="assets/img/menu/home-3.jpg" alt="" />
                                                            <div class="homemenu-btn">
                                                                <a class="tp-menu-btn" href="index-3.html">Multi page</a>
                                                                <a class="tp-menu-btn" href="index-3-one-page.html">One Page</a>
                                                            </div>
                                                        </div>
                                                        <div class="homemenu-content text-center">
                                                            <h4 class="homemenu-title">
                                                                <a href="index-3.html">Home 03</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col homemenu">
                                                        <div class="homemenu-thumb mb-15">
                                                            <img src="assets/img/menu/home-4.jpg" alt="" />
                                                            <div class="homemenu-btn">
                                                                <a class="tp-menu-btn" href="index-4.html">Multi page</a>
                                                                <a class="tp-menu-btn" href="index-4-one-page.html">One Page</a>
                                                            </div>
                                                        </div>
                                                        <div class="homemenu-content text-center">
                                                            <h4 class="homemenu-title">
                                                                <a href="index-4.html">Home 04</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col homemenu">
                                                        <div class="homemenu-thumb mb-15">
                                                            <img src="assets/img/menu/home-5.jpg" alt="" />
                                                            <div class="homemenu-btn">
                                                                <a class="tp-menu-btn" href="index-5.html">Multi page</a>
                                                                <a class="tp-menu-btn" href="index-5-one-page.html">One Page</a>
                                                            </div>
                                                        </div>
                                                        <div class="homemenu-content text-center">
                                                            <h4 class="homemenu-title">
                                                                <a href="index-5.html">Home 05</a>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                                            <li>
                                                <Link to="/home">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li>
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/projects">Projects</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                            {/* <li class="has-dropdown-2">
                                            <a href="service.html">Services</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="service.html">Service</a></li>
                                                <li><a href="service-details.html">Service Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="project.html">Projects</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="project.html">Project</a></li>
                                                <li><a href="project-details.html">Project Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="blog-classic.html">Blog</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="blog-classic.html">Blog Classic</a></li>
                                                <li><a href="blog-details.html">Blog Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="#">Page</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="team.html">Team</a></li>
                                                <li><a href="team-details.html">Team Details</a></li>
                                                <li><a href="appointment.html">appointment</a></li>
                                                <li><a href="faq.html">Faq</a></li>
                                                <li><a href="price.html">price</a></li>
                                                <li><a href="shop.html">shop</a></li>
                                                <li><a href="shop-details.html">Shop Details</a></li>
                                                <li><a href="cart.html">cart</a></li>
                                                <li><a href="checkout.html">checkout</a></li>
                                                <li><a href="error.html">error</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="contact.html">Contact</a></li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-8 col-6">
                                <div class="tp-header-right d-flex align-items-center justify-content-end">
                                    {/* <div class="tp-header-icon cart d-none d-xl-block">
                                        <a class="cart-icon p-relative" href="cart.html">
                                            <i class="fa-sharp fa-solid fa-cart-shopping shopping-cart"></i>
                                            <span>
                                                <i class="far fa-plus"></i>
                                            </span>
                                        </a>
                                        <div class="minicart">
                                            <div class="cart-content-wrap d-flex align-items-center justify-content-between">
                                                <div class="cart-img-info d-flex align-items-center">
                                                    <div class="cart-thumb">
                                                        <a href="shop-details.html"> <img src="assets/img/product/product-1.jpg" alt="" /></a>
                                                    </div>
                                                    <div class="cart-content">
                                                        <h5 class="cart-title"><a href="shop-details.html">Mobile - phone</a></h5>
                                                        <span> $ 22.9 <del>$39.9</del></span>
                                                    </div>
                                                </div>
                                                <div class="cart-del-icon">
                                                    <span><i class="fa-light fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                            <div class="cart-content-wrap d-flex justify-content-between align-items-center">
                                                <div class="cart-img-info  d-flex align-items-center">
                                                    <div class="cart-thumb">
                                                        <a href="shop-details.html"> <img src="assets/img/product/product-2.jpg" alt="" /></a>
                                                    </div>
                                                    <div class="cart-content">
                                                        <h5 class="cart-title"><a href="shop-details.html">Gothelf learn UX</a></h5>
                                                        <span> $ 22.9 <del>$39.9 </del></span>
                                                    </div>
                                                </div>
                                                <div class="cart-del-icon">
                                                    <span><i class="fa-light fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                            <div class="cart-total-price d-flex align-items-center justify-content-between">
                                                <span>Total:</span>
                                                <span>$100.0</span>
                                            </div>
                                            <div class="cart-btn">
                                                <a class="tp-btn-theme height-2 w-100 mb-10" href="cart.html"><span>Shopping Cart</span></a>
                                                <a class="tp-btn-black w-100" href="checkout.html"><span>Checkout</span></a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="tp-header-btn d-none d-md-block">
                                        <a href="/Brochure.pdf" download="Brochure.pdf">
                                            <p class="tp-btn-border black-border">Get Brochure </p>
                                        </a>
                                    </div>
                                    <div class="tp-header-bar d-xl-none" onClick={opencloseMobileMenu}>
                                        <button class="tp-menu-bar"><i class="fa-solid fa-bars"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="tp-header-area tp-header-style-3 tp-int-menu tp-header-sticky-cloned grey-bg z-index-5">
                    <div class="container custom-container-1">
                        <div class="row align-items-center">
                            <div class="col-xl-2 col-lg-4 col-6">
                                <div class="tp-header-logo">
                                    <Link to='/home'><img src={logo} alt="" /></Link>
                                </div>
                            </div>
                            <div class="col-xl-7 d-none d-xl-block">
                                <div class="tp-header-menu">
                                    <nav class="tp-main-menu-content">
                                        <ul>
                                            {/* <li class="has-dropdown-2">
                                                <a href="index.html">Home</a>
                                                <div class="tp-submenu submenu has-homemenu">
                                                    <div class="row gx-6 row-cols-1 row-cols-md-2 row-cols-xl-5">
                                                        <div class="col homemenu">
                                                            <div class="homemenu-thumb mb-15">
                                                                <img src="assets/img/menu/home-1.jpg" alt="" />
                                                                <div class="homemenu-btn">
                                                                    <a class="tp-menu-btn" href="index.html">Multi page</a>
                                                                    <a class="tp-menu-btn" href="index-one-page.html">One Page</a>
                                                                </div>
                                                            </div>
                                                            <div class="homemenu-content text-center">
                                                                <h4 class="homemenu-title">
                                                                    <a href="index.html">Home 01</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="col homemenu">
                                                            <div class="homemenu-thumb mb-15">
                                                                <img src="assets/img/menu/home-2.jpg" alt="" />
                                                                <div class="homemenu-btn">
                                                                    <a class="tp-menu-btn" href="index-2.html">Multi page</a>
                                                                    <a class="tp-menu-btn" href="index-2-one-page.html">One Page</a>
                                                                </div>
                                                            </div>
                                                            <div class="homemenu-content text-center">
                                                                <h4 class="homemenu-title">
                                                                    <a href="index-2.html">Home 02</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="col homemenu">
                                                            <div class="homemenu-thumb mb-15">
                                                                <img src="assets/img/menu/home-3.jpg" alt="" />
                                                                <div class="homemenu-btn">
                                                                    <a class="tp-menu-btn" href="index-3.html">Multi page</a>
                                                                    <a class="tp-menu-btn" href="index-3-one-page.html">One Page</a>
                                                                </div>
                                                            </div>
                                                            <div class="homemenu-content text-center">
                                                                <h4 class="homemenu-title">
                                                                    <a href="index-3.html">Home 03</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="col homemenu">
                                                            <div class="homemenu-thumb mb-15">
                                                                <img src="assets/img/menu/home-4.jpg" alt="" />
                                                                <div class="homemenu-btn">
                                                                    <a class="tp-menu-btn" href="index-4.html">Multi page</a>
                                                                    <a class="tp-menu-btn" href="index-4-one-page.html">One Page</a>
                                                                </div>
                                                            </div>
                                                            <div class="homemenu-content text-center">
                                                                <h4 class="homemenu-title">
                                                                    <a href="index-4.html">Home 04</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="col homemenu">
                                                            <div class="homemenu-thumb mb-15">
                                                                <img src="assets/img/menu/home-5.jpg" alt="" />
                                                                <div class="homemenu-btn">
                                                                    <a class="tp-menu-btn" href="index-5.html">Multi page</a>
                                                                    <a class="tp-menu-btn" href="index-5-one-page.html">One Page</a>
                                                                </div>
                                                            </div>
                                                            <div class="homemenu-content text-center">
                                                                <h4 class="homemenu-title">
                                                                    <a href="index-5.html">Home 05</a>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> */}
                                            <li>
                                                <Link to="/home">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about">About</Link>
                                            </li>
                                            <li>
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/projects">Projects</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                            {/* <li class="has-dropdown-2">
                                            <a href="service.html">Services</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="service.html">Service</a></li>
                                                <li><a href="service-details.html">Service Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="project.html">Projects</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="project.html">Project</a></li>
                                                <li><a href="project-details.html">Project Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="blog-classic.html">Blog</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="blog-classic.html">Blog Classic</a></li>
                                                <li><a href="blog-details.html">Blog Details</a></li>
                                            </ul>
                                        </li>
                                        <li class="has-dropdown-2">
                                            <a href="#">Page</a>
                                            <ul class="submenu tp-submenu">
                                                <li><a href="team.html">Team</a></li>
                                                <li><a href="team-details.html">Team Details</a></li>
                                                <li><a href="appointment.html">appointment</a></li>
                                                <li><a href="faq.html">Faq</a></li>
                                                <li><a href="price.html">price</a></li>
                                                <li><a href="shop.html">shop</a></li>
                                                <li><a href="shop-details.html">Shop Details</a></li>
                                                <li><a href="cart.html">cart</a></li>
                                                <li><a href="checkout.html">checkout</a></li>
                                                <li><a href="error.html">error</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="contact.html">Contact</a></li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-8 col-6">
                                <div class="tp-header-right d-flex align-items-center justify-content-end">
                                    {/* <div class="tp-header-icon cart d-none d-xl-block">
                                        <a class="cart-icon p-relative" href="cart.html">
                                            <i class="fa-sharp fa-solid fa-cart-shopping shopping-cart"></i>
                                            <span>
                                                <i class="far fa-plus"></i>
                                            </span>
                                        </a>
                                        <div class="minicart">
                                            <div class="cart-content-wrap d-flex align-items-center justify-content-between">
                                                <div class="cart-img-info d-flex align-items-center">
                                                    <div class="cart-thumb">
                                                        <a href="shop-details.html"> <img src="assets/img/product/product-1.jpg" alt="" /></a>
                                                    </div>
                                                    <div class="cart-content">
                                                        <h5 class="cart-title"><a href="shop-details.html">Mobile - phone</a></h5>
                                                        <span> $ 22.9 <del>$39.9</del></span>
                                                    </div>
                                                </div>
                                                <div class="cart-del-icon">
                                                    <span><i class="fa-light fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                            <div class="cart-content-wrap d-flex justify-content-between align-items-center">
                                                <div class="cart-img-info  d-flex align-items-center">
                                                    <div class="cart-thumb">
                                                        <a href="shop-details.html"> <img src="assets/img/product/product-2.jpg" alt="" /></a>
                                                    </div>
                                                    <div class="cart-content">
                                                        <h5 class="cart-title"><a href="shop-details.html">Gothelf learn UX</a></h5>
                                                        <span> $ 22.9 <del>$39.9 </del></span>
                                                    </div>
                                                </div>
                                                <div class="cart-del-icon">
                                                    <span><i class="fa-light fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                            <div class="cart-total-price d-flex align-items-center justify-content-between">
                                                <span>Total:</span>
                                                <span>$100.0</span>
                                            </div>
                                            <div class="cart-btn">
                                                <a class="tp-btn-theme height-2 w-100 mb-10" href="cart.html"><span>Shopping Cart</span></a>
                                                <a class="tp-btn-black w-100" href="checkout.html"><span>Checkout</span></a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="tp-header-btn d-none d-md-block">
                                        <a href="/Brochure.pdf" download="Brochure.pdf">
                                            <p class="tp-btn-border black-border">Get Brochure </p>
                                        </a>
                                    </div>
                                    <div class="tp-header-bar d-xl-none">
                                        <button class="tp-menu-bar"><i class="fa-solid fa-bars"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        </>

    )
}

export default Topbar
