import React from 'react';
import whatsappIcon from '../assets/img/whatsapp.png'; // Adjust the path accordingly

const FloatingButton = () => {
  const phoneNumber = '+918708507953'; // Replace with your phone number
  const message = 'I am inquiring about the services at manishbansaldesigns.com ';
  const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
  };

  const imgStyle = {
    width: '40px',
    height: '40px',
  };

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={buttonStyle}>
      <img src={whatsappIcon} alt="WhatsApp" style={imgStyle} />
    </a>
  );
};

export default FloatingButton;
