import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Footer from './shared/Footer';
import Topbar from './shared/Topbar';
import Menubar from './shared/Menubar';
import Project from './Project';
import ScrollToTop from './shared/ScrollToTop'; // Import the ScrollToTop'; // Import the ScrollToTop component
import FloatingButton from './shared/FloatingButton';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Add ScrollToTop here */}
      <FloatingButton />
      <Topbar />
      <Menubar />
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/projects' element={<Project />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/' element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
