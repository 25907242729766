import React from 'react'
import { Formik, Field, Form } from 'formik'
import axios from 'axios'


const Contact = () => {
   const formValues = {
      name: "",
      email: "",
      contact: "",
      address: "",
      message: "",
      subject: ""
   }
   const submitAddress = async (values) => {
      try {
         console.log(values)

         //  await axios.post(`${process.env.REACT_APP_API_URL}mbdesigns/contactus`, values)
         await axios.post(`${process.env.REACT_APP_API_URL}mbdesigns/contactus`, values)


      }
      catch (error) {
         console.log(error)
      }
   }
   return (
      <>
         <main>
            <div className="breadcrumb__pt">
               <div
                  className="breadcrumb__area breadcrumb__overlay breadcrumb__height p-relative fix"
                  style={{ backgroundImage: 'url(assets/img/breadcurmb/breadcurmb.jpg)' }}
               >
                  <div className="container">
                     <div class="row">
                        <div class="col-xxl-12">
                           <div class="breadcrumb__content z-index">
                              <div class="breadcrumb__section-title-box mb-20">
                                 <h3 class="breadcrumb__title tp-split-text tp-split-in-right">Contact Us</h3>
                              </div>
                              <div class="breadcrumb__list">
                                 <span><a href="index.html">Home</a></span>
                                 <span class="dvdr"><i class="fa-solid fa-angle-right"></i></span>
                                 <span>Contact Us</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="tp-form-area pt-145 pb-150">
               <div class="container">
                  <div class="tp-form-top pb-100">
                     <div class="row">

                        {/* <h3 className='text-center mb-5'>Address Create</h3>
                        <Formik initialValues={formValues} onSubmit={(values => submitAddress(values))}>
                           <Form>


                              <div className='row mb-2'>
                                 <label className='col-4 my-2 text-center'>Name:-</label>
                                 <Field name="name" type="text" className='col-6' required />
                              </div>
                              <div className="row mb-2">
                                 <label className='col-4 my-2 text-center'>Email:-</label>
                                 <Field name="email" type="text" className='col-6' required />
                              </div>
                              <div className="row mb-2">
                                 <label className='col-4 my-2 text-center'>Contact:-</label>
                                 <Field name="contact" type="text" className='col-6' required />
                              </div>
                              <div className="row mb-2">
                                 <label className='col-4 my-2 text-center'>Address:-</label>
                                 <Field name="address" type="text" className='col-6' required />
                              </div>
                              <div className='row mb-2'>
                                 <label className='col-4 my-2 text-center'>Message:-</label>
                                 <Field name="message" type="text" className='col-6' required />
                              </div>
                              <div className='row mb-2'>
                                 <label className='col-4 my-2 text-center'>Subject:-</label>
                                 <Field name="subject" type="text" className='col-6' required />
                              </div>
                              <div className='text-center my-4'>
                                 <button type='submit' className='py-1 '>Submit</button>
                              </div>
                           </Form>
                        </Formik> */}



                        <div class="col-xl-8 col-lg-8 mb-50">
                           <div class="tp-form-box tp-form-box-style-2">
                              <h4 class="tp-section-title pb-60">Enter You Query</h4>
                              <Formik initialValues={formValues} onSubmit={(values => submitAddress(values))}>
                           <Form>
                              {/* <form id="contact-form" action="https://html.hixstudio.net/interno-prev/interno/assets/mail.php" method="POST"> */}
                                 <div class="row">
                                    <div class="col-xl-6 col-lg-6 mb-30">
                                       <div class="tp-form-input-box">
                                          {/* <input name="name" type="text" placeholder="Name"></input> */}
                                          <Field name="name" type="text" className='col-6'  placeholder="Name" required />
                                       </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 mb-30">
                                       <div class="tp-form-input-box">
                                          {/* <input name="email" type="email" placeholder="Your Email"></input> */}
                                          <Field name="email" type="text" className='col-6' placeholder="Your Email" required />
                                       </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 mb-30">
                                       <div class="tp-form-input-box">
                                          {/* <input name="phone" type="text" placeholder="Phone"></input> */}
                                          <Field name="contact" type="text" className='col-6' placeholder="Phone" required />
                                       </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 mb-30">
                                       <div class="tp-form-input-box">
                                          {/* <input name="address" type="text" placeholder="Address"></input> */}
                                          <Field name="address" type="text" className='col-6' placeholder="Address" required />
                                       </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 mb-30">
                                       <div class="tp-form-input-box">
                                       {/* <input name="subject" type="text" placeholder="Subject"></input> */}
                                       <Field name="subject" type="text" className='col-6' placeholder="Subject" required />
                                       </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 mb-30">
                                       <div class="tp-form-textarea-box tp-form-input-box">
                                          {/* <textarea name="message" placeholder="Messege"></textarea> */}
                                          <Field name="message" type="text" className='col-6' placeholder="Messege" required />
                                       </div>
                                    </div>
                                 </div>
                                 <button class="tp-btn-theme black-bg" type="submit"><span>Message Us</span></button>
                                 <p class="ajax-response"></p>
                              {/* </form> */}

                              </Form>
                        </Formik>
                           </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 mb-50">
                           <div class="tp-contact-box">
                              <h4 class="tp-section-title pb-10">Get in touch </h4>
                              {/* <p class="pb-25">It is a long established fact that a reader will be distrol acted bioiiy desig the rea dablea contentIt is a long established fact that a reader will be </p> */}
                              <ul>
                                 <li>
                                    <div class="tp-contact-item d-flex align-items-center">
                                       <div class="tp-contact-icon-2">
                                          <span>
                                             <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.5625 20.0312C4.53125 17.4922 0 11.4375 0 8C0 3.85938 3.32031 0.5 7.5 0.5C11.6406 0.5 15 3.85938 15 8C15 11.4375 10.4297 17.4922 8.39844 20.0312C7.92969 20.6172 7.03125 20.6172 6.5625 20.0312ZM7.5 10.5C8.86719 10.5 10 9.40625 10 8C10 6.63281 8.86719 5.5 7.5 5.5C6.09375 5.5 5 6.63281 5 8C5 9.40625 6.09375 10.5 7.5 10.5Z" fill="currentcolor" />
                                             </svg>
                                          </span>
                                       </div>
                                       <div class="tp-contact-content">
                                          <h6>Address</h6>
                                          3/6, U Block,DLF Phase-3, Sector-24, Gurugram Haryana-122002
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div class="tp-contact-item d-flex align-items-center">
                                       <div class="tp-contact-icon-2">
                                          <span>
                                             <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.125 0C19.1406 0 20 0.859375 20 1.875C20 2.5 19.6875 3.04688 19.2188 3.39844L10.7422 9.76562C10.2734 10.1172 9.6875 10.1172 9.21875 9.76562L0.742188 3.39844C0.273438 3.04688 0 2.5 0 1.875C0 0.859375 0.820312 0 1.875 0H18.125ZM8.47656 10.7812C9.375 11.4453 10.5859 11.4453 11.4844 10.7812L20 4.375V12.5C20 13.9062 18.8672 15 17.5 15H2.5C1.09375 15 0 13.9062 0 12.5V4.375L8.47656 10.7812Z" fill="currentcolor" />
                                             </svg>
                                          </span>
                                       </div>
                                       <div class="tp-contact-content">
                                          <h6>Email Address</h6>
                                          manishbansaldesigns@gmail.com
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div class="tp-contact-item d-flex align-items-center">
                                       <div class="tp-contact-icon-2">
                                          <span>
                                             <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.9609 15.6172L19.0234 19.5625C18.9062 20.1484 18.4375 20.5391 17.8516 20.5391C8.00781 20.5 0 12.4922 0 2.64844C0 2.0625 0.351562 1.59375 0.9375 1.47656L4.88281 0.539062C5.42969 0.421875 6.01562 0.734375 6.25 1.24219L8.08594 5.5C8.28125 6.00781 8.16406 6.59375 7.73438 6.90625L5.625 8.625C6.95312 11.3203 9.14062 13.5078 11.875 14.8359L13.5938 12.7266C13.9062 12.3359 14.4922 12.1797 15 12.375L19.2578 14.2109C19.7656 14.4844 20.0781 15.0703 19.9609 15.6172Z" fill="currentcolor" />
                                             </svg>
                                          </span>
                                       </div>
                                       <div class="tp-contact-content">
                                          <h6>Phone number & Whatsapp</h6>
                                          8708507953, 7404288715
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <div class="row">
         <div class="col-12">
            <div class="tp-map-box">
               <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d146513.05509247648!2d73.19133525789097!3d54.98596156928781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43aafde2f601090b%3A0x5eefc33861a69b1a!2z4KaT4Kau4Ka44KeN4KaVLCBPbXNrIE9ibGFzdCwg4Kaw4Ka-4Ka24Ka_4Kav4Ka84Ka-!5e0!3m2!1sbn!2sbd!4v1689181288902!5m2!1sbn!2sbd"
               width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
               referrerpolicy="no-referrer-when-downgrade">
               </iframe>
            </div>
         </div>
      </div> */}


            <div class="tp-brand-area black-bg pt-100 fix pb-100">
               <div class="container custom-container-2">
                  <div class="row">
                     <div class="col-12">
                        <div class="tp-brand-wrapper">
                           <div class="swiper-container tp-brand-active">
                              <div class="swiper-wrapper">
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-1.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-2.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-3.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-4.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-5.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-6.png" alt=""></img>
                                    </div>
                                 </div>
                                 <div class="swiper-slide">
                                    <div class="tp-brand-item text-center">
                                       <img src="assets/img/brand/brand-2-2.png" alt=""></img>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


         </main>
      </>
   )


}

export default Contact
